import { CustomTyphography, Flexbox } from 'components'
import { Stars } from 'components/icons'
import { FC, useEffect, useRef } from 'react'
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const classes = classNames.bind(styles);

interface MockupPanelProps {
    content?: string,
    setAiRefineDialog: (arg: {
        open: boolean,
        field: string,
        type: 'prd' | 'mockup',
        content?: string
    }) => void,
    streaming?: boolean
}

const MockupPanel: FC<MockupPanelProps> = ({ content, setAiRefineDialog, streaming }) => {
    const mockupRef = useRef<HTMLDivElement>(null);

    const openAiRefineDialog = () => {
        setAiRefineDialog({
            open: true,
            field: 'mockup',
            type: 'mockup',
            content
        })
    }

    useEffect(() => {
        if (mockupRef.current && streaming) {
            mockupRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [streaming, content, mockupRef.current]);

    return (
        <Flexbox fullWidth vertical className={classes('sidePanel-mainContent', 'gap-4')}>
            {content && (
                streaming ? (
                    <Flexbox ref={mockupRef}>
                        <code style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{content}</code>
                    </Flexbox>
                ) : (
                    <Flexbox vertical className={classes('gap-4')}>
                        <Flexbox align
                            className={classes('cursor-pointer', 'gap-1', 'refine-btn')}
                            onClick={() => openAiRefineDialog()}
                            data-copy='false'
                        >
                            <Stars />
                            <CustomTyphography>AI Refine</CustomTyphography>
                        </Flexbox>
                        <iframe
                            srcDoc={content.replace(/(<a\b[^>]*\bhref=["'])#(["'][^>]*>)/gi, '$1javascript:void(0);$2')}
                            style={{ height: '70vh', border: 'none' }}
                        />
                    </Flexbox>
                )
            )}
        </Flexbox>
    )
}

export default MockupPanel